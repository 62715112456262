<template>
  <div>
    <b-overlay :show="show">
      <b-row>
        <b-col
          v-if="!decodedBase64Object"
          style="display: flex; justify-content: center"
          cols="12"
        >
          <h1 class="text-primary text-uppercase">
            ¡Escanea el código QR!
          </h1>
        </b-col>
        <b-col
          style="display: flex; justify-content: center"
          cols="12"
        >
          <b-img
            :src="require('/public/qr-code-icon.gif')"
            alt="Scan Animation"
            style="width: 180px; height: 180px; border-radius: 15px; padding: 5px; box-shadow: 10px 12px 25px rgba(0, 0, 0, 0.5);"
          />
        </b-col>
      </b-row>
      <br>
      <b-row>
        <b-col
          v-if="decodedBase64Object"
          cols="6"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="relief-primary"
            @click="openForm"
          >
            <feather-icon
              icon="CpuIcon"
              class="mr-50"
            />
            Ingresar Luminaria QR
          </b-button>
        </b-col>
        <b-col
          v-if="decodedBase64Object"
          cols="6"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="relief-primary"
            @click="scanNewQRCode"
          >
            <feather-icon
              icon="CameraIcon"
              class="mr-50"
            />
            Escanear un nuevo código QR
          </b-button>
        </b-col>
        <b-col
          style="position: absolute; top: -9999px;"
          cols="12"
        >
          <h6 class="text-primary" />
          <b-form-input
            ref="barcodeInput"
            v-model.trim="scannedData"
            :disabled="!inputHabilitado"
            @change="decodeBase64"
          />
        </b-col>
      </b-row>
      <br>
      <b-row v-if="decodedBase64Object">
        <b-col
          style="display: flex; justify-content: center"
          cols="6"
        >
          <h5 class="text-primary">
            Serie de Luminaria
          </h5>
          <b-form-input
            v-model="decodedBase64Object.codigo"
            disabled
          />
        </b-col>
        <b-col
          style="display: flex; justify-content: center"
          cols="6"
        >
          <h5 class="text-primary">
            Poste de Luminaria
          </h5>
          <b-form-input
            v-model="decodedBase64Object.numeroPoste"
            disabled
          />
        </b-col>
      </b-row>
      <br>
      <b-row v-if="decodedBase64Object">
        <b-col cols="12">
          <vue-good-table
            :columns="columns"
            :rows="rows"
            style-class="vgt-table condensed"
            :pagination-options="{
              enabled: true,
              perPage: pageLength
            }"
            :search-options="{
              enabled: true,
              placeholder: 'Buscar',
            }"
            @on-row-dblclick="showDetalle"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span v-if="props.column.field == 'estado'">
                <b-badge :variant="getColorClass(props.row)">
                  {{ props.row.estado }}
                </b-badge>
              </span>
            </template>
            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Mostrar
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['10', '15', '20']"
                    class="mx-1"
                    @input="value => props.perPageChanged({ currentPerPage: value })"
                  />
                  <span class="text-nowrap"> de {{ props.total }} registros </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="value => props.pageChanged({ currentPage: value })"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-col>
      </b-row>
      <div>
        <b-modal
          id="modal-bodega"
          v-model="detalleBodega"
          title="Detalle de Recepción de Luminaria"
          size="lg"
          ok-only
          ok-title="Aceptar"
          no-close-on-backdrop
        >
          <detalle-bodega
            :luminaria="luminaria"
            @cerrar-detalle="cerrarDetalle"
          />
        </b-modal>
      </div>
    </b-overlay>
  </div>
</template>
<script>
import {
  BRow, BCol, BButton, BFormInput, BOverlay, BImg, BBadge, BFormSelect, BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import * as Base64 from 'js-base64'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mensajeError } from '@/utils/mensajes'
import { VueGoodTable } from 'vue-good-table'
import { findLuminarias, getLuminaria } from '@/utils/mantenimiento/bodega/bodegaUtils'
import DetalleBodega from '@/components/mantenimiento/bodega/detalle-bodega.vue'
import { calcularFecha } from '@/utils/fechas'

export default {
  name: 'Escaner',
  components: {
    DetalleBodega,
    VueGoodTable,
    BCol,
    BRow,
    BImg,
    BBadge,
    BButton,
    BOverlay,
    BFormInput,
    BFormSelect,
    BPagination,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      scannedData: '',
      decodedBase64: '',
      luminaria: null,
      detalleBodega: false,
      decodedBase64Object: '',
      intervalId: null,
      columns: [
        {
          label: 'No. Poste',
          field: 'numeroPoste',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Serie Lámpara',
          field: 'codigo',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Fecha/Hora',
          field: 'fechaCreacion',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
        {
          label: 'Estado',
          field: 'estado',
          tdClass: 'text-center',
          thClass: 'vgt-center-align',
        },
      ],
      rows: [],
      pageLength: 15,
      usuario: JSON.parse(localStorage.getItem('userData')),
      inputHabilitado: true,
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    scannedData(newVal) {
      this.decodeBase64()
    },
    decodedBase64Object: {
      handler() {
        this.loadReport()
      },
      deep: true,
    },
  },
  async created() {
    this.intervalId = setInterval(() => {
      this.$nextTick(() => {
        this.$refs.barcodeInput.focus()
      })
    }, 500)
    await this.loadReport()
  },
  beforeDestroy() {
    clearInterval(this.intervalId)
  },
  methods: {
    async loadReport() {
      try {
        await this.$nextTick()
        const filter = {
          where: {
            and: [
              { companyId: this.usuario.idCompany },
            ],
          },
          order: ['fechaCreacion DESC'],
        }

        if (this.decodedBase64Object && this.decodedBase64Object.codigo !== '') {
          filter.where.and.push({ codigo: this.decodedBase64Object.codigo })
        }

        if (this.decodedBase64Object && this.decodedBase64Object.numeroPoste !== '') {
          filter.where.and.push({ numeroPoste: this.decodedBase64Object.numeroPoste })
        }

        const data = await findLuminarias(filter)
        // eslint-disable-next-line no-restricted-syntax
        for await (const item of data) {
          item.fechaCreacion = calcularFecha(item.fechaCreacion)
        }
        await new Promise(resolve => setTimeout(resolve, 0))
        this.rows = data
      } catch (error) {
        console.error('Error loading report:', error)
      } finally {
        this.show = false
      }
    },
    async decodeBase64() {
      try {
        if (this.isProcessing || !this.scannedData.trim()) {
          return
        }

        this.isProcessing = true
        this.show = true

        await new Promise(resolve => setTimeout(resolve, 1500))

        try {
          this.decodedBase64 = Base64.decode(this.scannedData)
          this.decodedBase64Object = JSON.parse(this.decodedBase64)
          this.inputHabilitado = false
        } catch (error) {
          mensajeError('Código QR no válido, Intenta nuevamente!')
          this.clearInput()
        } finally {
          this.isProcessing = false
          this.show = false
        }
      } catch (error) {
        console.error('Error decoding Base64:', error)
        this.decodedBase64 = 'Error al decodificar Base64'
      }
    },
    openForm() {
      if (this.scannedData) {
        this.newWindow = window.open('', '_blank')
        this.newWindow.location.href = `/recepcion-bodega?action=Create&scannedData=${encodeURIComponent(this.decodedBase64)}`
      }
    },
    async scanNewQRCode() {
      this.clearInput()
      await this.$nextTick()

      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Escanea un nuevo código QR!',
          icon: 'CameraIcon',
          variant: 'warning',
        },
      })
    },
    clearInput() {
      this.inputHabilitado = true
      this.scannedData = ''
      this.decodedBase64Object = ''
      this.decodedBase64 = ''
    },
    getColorClass(row) {
      if (row && row.estado === 'INGRESADA') {
        return 'light-danger'
        // eslint-disable-next-line no-mixed-operators
      } if (row && row.estado === 'REPARACIÓN' || row && row.estado === 'GARANTÍA') {
        return 'light-warning'
      } if (row && row.estado === 'REPARADA') {
        return 'light-primary'
      } if (row && row.estado === 'MANTENIMIENTO DE LUMINARIA') {
        return 'light-secondary'
      } if (row && row.estado === 'LUMINARIA PARA DESPACHO') {
        return 'light-info'
      } if (row && row.estado === 'LUMINARIA DESPACHADA') {
        return 'light-success'
      }
      return ''
    },
    cerrarDetalle() {
      this.loadReport()
      this.detalleBodega = false
    },
    async showDetalle(params) {
      const luminaria = await getLuminaria(params.row.id)
      if (luminaria) {
        this.luminaria = luminaria
        this.detalleBodega = true
      }
    },
  },
}
</script>

<style scoped>

</style>
